nav {
  padding: 34px 34px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #1f1f1f;
}

nav .logo {
  width: 69px;
  height: 69px;
}

nav .nav-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

nav .nav-bar .nav-item {
  padding: 7px 15px;
  margin: 0 20px;
  text-decoration: none;
  color: black;
  font-size: 1.3em;
  transition: 0.2s all;
}

nav .nav-bar .nav-item:hover {
  background: #ffd700;
}

.m-socials {
  display: none;
}

nav .socials img,
nav .m-socials img {
  width: 34px;
  margin: 0 17px;
  filter: brightness(0);
}

.container {
  position: relative;
  overflow: hidden;

  max-width: 1140px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.landing {
  padding-bottom: 120px;
}

.landing h1 {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 24em;
  font-weight: 700;
}

.landing p {
  text-align: right;
  font-size: 1.5em;
  font-weight: 500;
  padding: 0;
  margin-right: 35%;
  margin-bottom: 80px;
}

.landing h3 {
  font-size: 3em;
  font-weight: 500;
  margin: 0 auto;
  padding: 0;
}

.landing .jinx_standing {
  width: 600px;
  position: absolute;
  top: 10vh;
  right: -150px;
}

@media (max-width: 992px) {
  .landing h3 {
    font-size: 3em;
  }
}

@media (max-width: 768px) {
  .landing h1 {
    text-align: center;
    font-size: 10em;
  }

  .landing h3 {
    font-size: 1.8em;
    text-align: center;
    margin-bottom: 80px;
  }

  .landing p {
    text-align: center;
    margin-right: 0;

  }

  .landing .jinx_standing {
    width: 600px;
    position: relative;
    top: -100px;
    right: 0;
  }
}

@media (max-width: 425px) {
  nav .nav-bar .nav-item {
    padding: 7px 8px;
    margin: 0 10px;
  }

  .socials {
    display: none;
  }

  .m-socials {
    display: block;
    margin: 25px auto;
  }

  nav .m-socials img {
    margin: 0 8px;
  }

  .landing p {
    margin-bottom: 20px;

  }
}

/* Meme Generator Styles */
.meme-generator {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000000;
  color: #ffd700;
  padding: 0 0 1rem 0;
  overflow-y: auto;
}

.meme-generator h1 {
  font-size: 3em;
  font-weight: 700;
  text-align: center;
  margin: 1rem 0 1rem 0;
}

.meme-content {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: visible;
}

@media (min-width: 768px) {
  .meme-generator h1 {
    font-size: 6em;
    margin: 1rem 0 2rem 0;
  }

  .meme-content {
    flex-direction: row;
    height: calc(100vh - 10rem);
    overflow-y: hidden;
  }
}

.canvas-and-controls-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .canvas-and-controls-container {
    flex-direction: row;
    height: auto;
  }

  .meme-canvas {
    flex: 65;
    width: 100%;
    padding: 1rem;
    height: auto;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .meme-controls {
    flex: 35;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 10rem);
    overflow-y: auto;
    max-width: 400px;
    /* Add this line to set max-width on desktop */
    margin-left: auto;
    /* Center the controls if it's smaller than its container */
  }

  .sticker-menu {
    flex-grow: 1;
    overflow-y: auto;
    max-height: calc(100% - 150px);
  }

  .sticker-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }

  .sticker-button {
    width: 100%;
    aspect-ratio: 1 / 1;
    padding: 0.25rem;
  }

  .pose-preview {
    max-width: 90%;
    max-height: 90%;
  }

  .action-buttons {
    margin-top: 1rem;
  }
}

.meme-canvas {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.canvas-wrapper canvas {
  max-width: 100% !important;
  max-height: 100% !important;
  width: auto !important;
  height: auto !important;
  border: none;
  background-color: transparent;
  object-fit: contain;
}

.file-upload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.upload-button {
  background-color: #000000;
  color: #ffd700;
  border: 2px solid #ffd700;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s, color 0.2s;
  font-weight: bold;
  text-transform: uppercase;
}

.upload-button:hover {
  background-color: #ffd700;
  color: #000000;
}

.meme-controls {
  flex: 3;
  padding: 1rem;
  background-color: #1f1f1f;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  overflow-y: visible;
}

@media (max-width: 767px) {
  .meme-controls {
    max-height: none;
    overflow-y: visible;
  }

  .canvas-and-controls-container {
    height: auto;
  }

  .meme-canvas {
    height: auto;
    flex: none;
    min-height: 400px;
  }

  .sticker-menu {
    height: auto;
    overflow-y: visible;
    flex-grow: 1;
  }
}

@media (min-width: 768px) {
  .meme-controls {
    padding: 2rem;
    justify-content: space-between;
    min-height: 300px;
    overflow-y: auto;
    max-height: calc(100vh - 12rem);
    flex: 35;
    max-width: 400px;
    /* Add this line to ensure max-width is applied */
  }
}

.image-type-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.image-type-button {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border: 2px solid #ffd700;
  background-color: #000000;
  color: #ffd700;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-weight: bold;
  text-transform: uppercase;
}

.image-type-button.active {
  background-color: #ffd700;
  color: #000000;
}

.image-type-button:hover {
  background-color: #ffd700;
  color: #000000;
}

.sticker-menu {
  overflow-y: auto;
  flex-grow: 1;
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .sticker-menu {
    height: auto;
    max-height: 500px;
    overflow-y: auto;
  }

  .sticker-grid {
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;
  }
}

.sticker-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  max-height: 100%;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .sticker-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.sticker-button {
  width: 100%;
  aspect-ratio: 1 / 1;
  padding: 0;
  background-color: #4a4a4a;
  border: 2px solid #ffd700;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sticker-button:hover {
  background-color: #ffd700;
}

.pose-preview {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

.action-buttons {
  display: flex;
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.action-buttons button {
  flex: 1;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s, color 0.2s;
  font-weight: bold;
  text-transform: uppercase;
}

.reset-button {
  background-color: #ffd700;
  color: #000000;
  border: 2px solid #ffd700;
}

.reset-button:hover {
  background-color: #000000;
  color: #ffd700;
}

.download-button {
  background-color: #000000;
  color: #ffd700;
  border: 2px solid #ffd700;
}

.download-button:hover {
  background-color: #ffd700;
  color: #000000;
}

.icon {
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
}

.konvajs-content {
  border: 1px solid #ffd700;
  max-width: 100% !important;
  max-height: 100% !important;
}

.konvajs-content canvas {
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: contain;
}

canvas {
  cursor: pointer;
}

.sticker-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ffd700;
}

.control-button {
  background-color: transparent;
  border: none;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.control-button:hover {
  background-color: rgba(255, 215, 0, 0.2);
}

.control-button svg {
  width: 20px;
  height: 20px;
  color: #ffd700;
}

.sticker-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Add these styles to your existing CSS */

.image-type-button:disabled,
.sticker-button:disabled,
.download-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.image-type-button:disabled:hover,
.sticker-button:disabled:hover,
.download-button:disabled:hover {
  background-color: #000000;
  color: #ffd700;
}

.meme-canvas {
  position: relative;
}

.centered-upload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.delete-button,
.mirror-button {
  /* Add styles similar to other buttons */
}

.delete-button:disabled,
.mirror-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Add these styles to your existing CSS */

.sticker-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ffd700;
  z-index: 100;
}

.control-button {
  background-color: transparent;
  border: none;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.control-button:hover {
  background-color: rgba(255, 215, 0, 0.2);
}

.control-button svg {
  width: 20px;
  height: 20px;
  color: #ffd700;
}

.sticker-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 767px) {
  .meme-canvas {
    width: 100vw;
    max-width: 100vw;
    height: auto;
    padding: 0;
    margin: 0;
  }

  .canvas-wrapper {
    width: 100vw;
    max-width: 100vw;
    padding: 0;
    margin: 0;
  }

  .canvas-wrapper canvas {
    width: 100vw !important;
    height: auto !important;
    max-width: none !important;
  }

  .meme-content {
    padding: 0;
  }

  .canvas-and-controls-container {
    width: 100vw;
    max-width: 100vw;
    padding: 0;
    margin: 0;
  }

  .meme-generator {
    padding: 0;
  }

  .meme-generator h1 {
    margin: 1rem 0;
  }
}