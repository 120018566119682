@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  padding: 0;

  width: 100%;
  height: 100vh;

  font-family: 'Barlow Condensed', sans-serif;
  font-weight: normal;

  background: #f3cc07;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 769px) {
  body {
    background-image: linear-gradient(rgb(255, 255, 255, .2) .05em, transparent .05em), linear-gradient(90deg, rgba(255, 255, 255, .2) .05em, transparent .05em);
    background-size: 34px 34px;
  }
}

@media (max-width: 768px) {
  body {
    background: #f3cc07 !important;
  }
}